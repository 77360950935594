import {
  createRouter,
  createWebHistory
} from 'vue-router'

function isAuthenticated() {
  return !!localStorage.getItem('authToken');
}

function isAuthenticatedTime() {
  const currentTime = Math.floor(Date.now() / 1000);
  const authTokenTime = localStorage.getItem('tokenTime');
  if (authTokenTime) {
    const timeDifference = currentTime - parseInt(authTokenTime, 10);
    const sevenDaysInSeconds = 7 * 24 * 60 * 60;
    return timeDifference <= sevenDaysInSeconds;
  }
  return false;
}

function isAdmin() {
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  return userInfo.is_superuser || userInfo.is_staff;
}

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue'),
    meta: {
      title: '登录到 校记招新'
    }
  },
  // {
  //   path: '/reg',
  //   name: 'reg',
  //   component: () => import('../views/RegView.vue'),
  //   meta: {
  //     title: 'SJCID'
  //   }
  // },
  {
    path: '/wework',
    name: 'wework',
    component: () => import('../views/Login/Wework.vue'),
    meta: {
      title: 'SJCID Wework'
    }
  },
  {
    path: '/idCode',
    name: 'sjccode',
    component: () => import('../views/idCode/idCode.vue'),
    meta: {
      title: '校记身份码',
      requiresAuth: true
    }
  },
  {
    path: '/idCode/check',
    name: 'sjccodecheck',
    component: () => import('../views/idCode/Check.vue'),
    meta: {
      title: '校记身份核验',
      requiresAuth: true
    }
  },
  {
    path: '/idCode/info',
    name: 'sjcInfo',
    component: () => import('../views/idCode/Info.vue'),
    meta: {
      title: '校记工作台',
      requiresAuth: true
    }
  },
  {
    path: '/mobile',
    name: 'mobile',
    component: () => import('../views/Res/mobile'),
    meta: {
      title: '校记招新',
      requiresAuth: false
    }
  },
  {
    path: '/msgCenter',
    name: 'msgCenter',
    component: () => import('../views/msgCenter'),
    meta: {
      title: '校记工作台',
      requiresAuth: true
    }
  },
  {
    path: '/msgCenter/send',
    name: 'msgCenterSend',
    component: () => import('../views/msgCenter/send'),
    meta: {
      title: '校记工作台',
      requiresAuth: true
    }
  },
  {
    path: '/contents',
    name: 'contents',
    component: () => import('../views/Content'),
    meta: {
      title: '校记工作台',
      requiresAuth: true
    }
  },
  {
    path: '/contents/add',
    name: 'contentsadd',
    component: () => import('../views/Content/add'),
    meta: {
      title: '校记工作台',
      requiresAuth: true
    }
  },
  {
    path: '/txl/search',
    name: 'txlSearch',
    component: () => import('../views/Txl/Search'),
    meta: {
      title: '校记工作台 SJCWS',
      requiresAuth: true
    }
  },
  {
    path: '/offer/:uuid',
    name: 'offer',
    component: () => import('../views/Res/offer'),
    meta: {
      title: 'SJC Offer',
      requiresAuth: true
    }
  },
  {
    path: '/',
    name: 'res',
    redirect: '/index',
    component: () => import('../views/Res/index'),
    meta: {
      title: '校记招新 SJCRes',
      requiresAuth: false
    },
    children: [
      {
        path: 'index',
        name: 'mainpage',
        component: () => import('../views/Res/mainpage'),
        meta: {
          title: '校记招新',
          requiresAuth: false
        }
      },
      {
        path: 'home',
        name: 'resHome',
        component: () => import('../views/Res/home'),
        meta: {
          title: '校记招新',
          requiresAuth: false
        }
      },
      {
        path: 'list',
        name: 'resList',
        component: () => import('../views/Res/list'),
        meta: {
          title: '校记招新',
          requiresAuth: true
        }
      },
      {
        path: 'about',
        name: 'resAbout',
        component: () => import('../views/Res/about'),
        meta: {
          title: '校记招新',
          requiresAuth: false
        }
      },
      {
        path: 'suggest',
        name: 'resSuggest',
        component: () => import('../views/Res/suggest'),
        meta: {
          title: '校记招新',
          requiresAuth: false
        }
      },
      {
        path: 'post/:id',
        name: 'resPost',
        component: () => import('../views/Res/post'),
        meta: {
          title: '校记招新',
          requiresAuth: false
        }
      },
      {
        path: 'post/:id/submit',
        name: 'resPostsubmit',
        component: () => import('../views/Res/submit'),
        meta: {
          title: '校记招新',
          requiresAuth: true
        }
      },
      {
        path: 'user',
        name: 'resUser',
        component: () => import('../views/Res/user'),
        meta: {
          title: '校记招新',
          requiresAuth: true
        }
      },
      {
        path: 'qun',
        name: 'resQun',
        component: () => import('../views/Res/qun'),
        meta: {
          title: '校记招新',
          requiresAuth: false
        }
      },
      {
        path: 'noright',
        name: 'noright',
        component: () => import('../views/Res/noright'),
        meta: {
          title: '校记招新',
          requiresAuth: false
        }
      },
    ]
  },
  {
    path: '/admin',
    name: 'admin',
    redirect: '/admin/home',
    component: () => import('../views/Admin/index'),
    meta: {
      title: '校记工作台',
      requiresAuth: true,
      requiresAdmin: true // 新增 meta 属性
    },
    children: [
      {
        path: 'home',
        name: 'adminHome',
        component: () => import('../views/Admin/home'),
        meta: {
          title: '校记工作台',
          requiresAuth: true,
          requiresAdmin: true // 新增 meta 属性
        }
      },
      {
        path: 'res',
        name: 'adminRes',
        component: () => import('../views/Admin/res'),
        meta: {
          title: '校记工作台',
          requiresAuth: true,
          requiresAdmin: true // 新增 meta 属性
        }
      },
      {
        path: 'res/:id',
        name: 'adminResInfo',
        component: () => import('../views/Admin/resinfo'),
        meta: {
          title: '校记工作台',
          requiresAuth: true,
          requiresAdmin: true // 新增 meta 属性
        }
      },
      {
        path: 'post',
        name: 'adminPost',
        component: () => import('../views/Admin/post'),
        meta: {
          title: '校记工作台',
          requiresAuth: true,
          requiresAdmin: true // 新增 meta 属性
        }
      },
      {
        path: 'plan',
        name: 'adminPlan',
        component: () => import('../views/Admin/plan'),
        meta: {
          title: '校记工作台',
          requiresAuth: true,
          requiresAdmin: true // 新增 meta 属性
        }
      },
      {
        path: 'post/new',
        name: 'adminNewPost',
        component: () => import('../views/Admin/newpost'),
        meta: {
          title: '校记工作台',
          requiresAuth: true,
          requiresAdmin: true // 新增 meta 属性
        }
      },
      {
        path: 'post/edit/:id',
        name: 'adminEditPost',
        component: () => import('../views/Admin/editpost'),
        meta: {
          title: '校记工作台',
          requiresAuth: true,
          requiresAdmin: true // 新增 meta 属性
        }
      },
      {
        path: 'user',
        name: 'adminUser',
        component: () => import('../views/Admin/user'),
        meta: {
          title: '校记工作台',
          requiresAuth: true,
          requiresAdmin: true // 新增 meta 属性
        }
      },
    ]
  },
  {
    path: '/m',
    name: 'mindex',
    redirect: '/m/index',
    component: () => import('../views/Mobile/index'),
    meta: {
      title: '校记招新',
      requiresAuth: false
    },
    children: [
      {
        path: 'index',
        name: 'mhome',
        component: () => import('../views/Mobile/home'),
        meta: {
          title: '校记招新',
          requiresAuth: false
        }
      },
      {
        path: 'user',
        name: 'muser',
        component: () => import('../views/Mobile/Info.vue'),
        meta: {
          title: '校记招新',
          requiresAuth: true
        }
      },
      {
        path: 'list',
        name: 'mlist',
        component: () => import('../views/Mobile/list.vue'),
        meta: {
          title: '校记招新',
          requiresAuth: true
        }
      },
      {
        path: 'post/:id',
        name: 'mPost',
        component: () => import('../views/Mobile/post'),
        meta: {
          title: '校记招新',
          requiresAuth: false
        }
      },
      {
        path: 'post/:id/submit',
        name: 'mPostSubmit',
        component: () => import('../views/Mobile/submit'),
        meta: {
          title: '校记招新',
          requiresAuth: true
        }
      },

    ],
  },
  {
    path: '/moffer/:uuid',
    name: 'moffer',
    component: () => import('../views/Mobile/offer'),
    meta: {
      title: 'SJC Offer',
      requiresAuth: true
    }
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// 全局导航守卫
router.beforeEach((to, from, next) => {

  const tokenTime = parseInt(localStorage.getItem('tokenTime')) || 0;
  const currentTime = Math.floor(new Date().getTime() / 1000);

  // 检查 tokenTime 是否超过七天（7 * 24 * 60 * 60 = 604800秒）
  if (tokenTime && (currentTime - tokenTime) >= 604800) {
    // 如果超过七天，不携带Token
    localStorage.removeItem('authToken');
    localStorage.removeItem('tokenTime'); 
    localStorage.removeItem('userInfo');
  }

  // 设置页面标题
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  // 检查需要登录的页面
  if (to.meta.requiresAuth && (!isAuthenticatedTime() || !isAuthenticated())) {
    const currentPath = encodeURIComponent(to.fullPath); // 对当前路径进行编码
    const pathstate = currentPath
    next({
      name: 'login',
      query: {
        state: pathstate
      }
    }); // 跳转到登录页面
  } else if (to.meta.requiresAdmin && !isAdmin()) {
    // 检查需要管理员权限的页面
    next({ name: 'noright' }); // 如果不是管理员，跳转到登录页面或其他处理
  } else {
    next(); // 继续导航
  }
})

// 全局后置守卫
router.afterEach(() => {
  // 滚动到页面顶部
  window.scrollTo(0, 0);
});

export default router
